import { AgentType, ChatData, QueryGroup } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(params: {
  orgId: string;
  pid: string;
}): Promise<QueryGroup[]> {
  return api
    .get<QueryGroup[]>(`/orgs/${params.orgId}/${params.pid}/query-groups`, {
      params,
    })
    .then(({ data }) => data);
}

export async function fetchOne(params: {
  orgId: string;
  pid: string;
  conversationId: string;
}): Promise<QueryGroup> {
  return api
    .get<QueryGroup>(
      `/orgs/${params.orgId}/${params.pid}/queries/${params.conversationId}`,
    )
    .then(({ data }) => data);
}

export async function createQueryGroup(params: {
  orgId: string;
  pid: string;
  query: string;
}) {
  return api.post<QueryGroup>(
    `/orgs/${params.orgId}/${params.pid}/query-group`,
    {
      title: params.query,
    },
  );
}

export async function createQuery(params: {
  orgId: string;
  pid: string;
  query: string;
  conversationId?: string;
  agentType: AgentType;
  parentId?: string;
}) {
  return api.post<ChatData>(
    `/orgs/${params.orgId}/${params.pid}/query/${params.agentType}`,
    {
      query: params.query,
      parentId: params.parentId,
      queryGroupId: params.conversationId,
    },
  );
}

export async function deleteQueryGroup(params: {
  orgId: string;
  pid: string;
  conversationId: string;
}) {
  return api.delete(
    `/orgs/${params.orgId}/${params.pid}/query-group/${params.conversationId}`,
  );
}
