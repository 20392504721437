import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  fetchAll,
  createQueryGroup,
  fetchOne,
  createQuery,
  deleteQueryGroup,
} from '../../services/chat-queries';
import { AgentType, ChatData, QueryGroup } from '../../types';
import useInvalidation from '../useInvalidation';
import { AxiosError, AxiosResponse } from 'axios';

const QUERY_GROUPS_KEY = 'query-groups';
const QUERY_GROUP_KEY = 'query-group';

export function useQueryGroups({
  params,
  options = {},
}: {
  params: Parameters<typeof fetchAll>[0];
  options?: Omit<UseQueryOptions<QueryGroup[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<QueryGroup[]>({
    queryKey: [QUERY_GROUPS_KEY, params],
    queryFn: () => fetchAll(params),
    ...options,
  });
}
export function useQueryGroup({
  params,
  options = {},
}: {
  params: Parameters<typeof fetchOne>[0];
  options?: Omit<UseQueryOptions<QueryGroup>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<QueryGroup>({
    queryKey: [QUERY_GROUP_KEY, params],
    queryFn: () => fetchOne(params),
    ...{
      keepPreviousData: true,
      ...options,
    },
  });
}

export function useNewQueryGroup({
  params,
  options = {},
}: {
  params: { orgId: string; pid: string };
  options?: UseMutationOptions<
    AxiosResponse<QueryGroup>,
    AxiosError<{ message: string }>,
    { query: string }
  >;
}) {
  const invalidateKey = useInvalidation(QUERY_GROUPS_KEY);
  return useMutation<
    AxiosResponse<QueryGroup>,
    AxiosError<{ message: string }>,
    { query: string }
  >({
    mutationFn: (mutationParams) =>
      createQueryGroup({ ...params, ...mutationParams }),
    ...{
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        return invalidateKey();
      },
      ...options,
    },
  });
}

export function useNewQuery({
  params,
  options = {},
}: {
  params: { orgId: string; pid: string };
  options?: UseMutationOptions<
    AxiosResponse<ChatData>,
    AxiosError<{
      message: string;
      response?: { data?: { error?: string; message?: string } };
    }>,
    {
      query: string;
      agentType: AgentType;
      parentId?: string;
      conversationId?: string;
    }
  >;
}) {
  const invalidateKey = useInvalidation(QUERY_GROUP_KEY);
  const invalidateGroupsKey = useInvalidation(QUERY_GROUPS_KEY);
  return useMutation<
    AxiosResponse<ChatData>,
    AxiosError<{
      message: string;
      response?: { data?: { error?: string; message?: string } };
    }>,
    {
      query: string;
      agentType: AgentType;
      parentId?: string;
      conversationId?: string;
    }
  >({
    mutationFn: (mutationParams) =>
      createQuery({ ...params, ...mutationParams }),
    ...{
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        invalidateGroupsKey();
        return invalidateKey();
      },
      ...options,
    },
  });
}
export function useDeleteQueryGroup({
  params,
  options = {},
}: {
  params: { orgId: string; pid: string };
  options?: UseMutationOptions<
    AxiosResponse<ChatData>,
    AxiosError<{
      message: string;
      response?: { data?: { error?: string; message?: string } };
    }>,
    {
      conversationId: string;
    }
  >;
}) {
  const invalidateKey = useInvalidation(QUERY_GROUPS_KEY);
  return useMutation<
    AxiosResponse<ChatData>,
    AxiosError<{
      message: string;
      response?: { data?: { error?: string; message?: string } };
    }>,
    {
      conversationId: string;
    }
  >({
    mutationFn: (mutationParams) =>
      deleteQueryGroup({ ...params, ...mutationParams }),
    ...{
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);
        return invalidateKey();
      },
      ...options,
    },
  });
}
