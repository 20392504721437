import { useAnalytics } from '@/utils/analytics';
import { setUser as setLoggingUser } from '@/utils/logging';
import { Auth, getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { app } from '@/lib/firebase';
import { createContext, ReactNode, useEffect, useState } from 'react';
import useUrlParams, { QueryParams } from '@formbio/use-url-params';
import { useNotificationContext } from '@/context/notificationContext';
import { useRouter } from 'next/router';
import { getHref, navRoutes } from '@/utils/routes';
import { SessionIdleDialog, LoadingPageProgress } from '@formbio/ui';
import { featureFlagIds } from '@/config';
import ReAuthDialog from '@/components/auth/ReAuthDialog';
import MFACodeDialog from '@/components/auth/MFACodeDialog';

export type AuthUser = User;

type AuthContextType = {
  auth: Auth;
  user: AuthUser | null;
  loading: boolean;
  // handle re-authentication
  reAuth: () => void;
  isAuthRecent: boolean;
  // handle mfa
  verifyMFA: (error: FirebaseError) => void;
  isMfaSuccess: boolean;
};

const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const { orgId, pid } = useUrlParams();
  const auth = getAuth(app);
  const analytics = useAnalytics();
  const ldClient = useLDClient();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | null>(null);
  const { setSnackbar } = useNotificationContext();
  const router = useRouter();
  const flags = useFlags();
  // re-auth
  const [isReAuthVisible, setIsReAuthVisible] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(false);
  const [isAuthRecent, setIsAuthRecent] = useState(false);
  // mfa
  const [isMFAVisible, setIsMFAVisible] = useState(false);
  const [mfaError, setMFAError] = useState<FirebaseError | undefined>(
    undefined,
  );
  const [isMfaSuccess, setIsMfaSuccess] = useState(false);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async user => {
      setLoading(false);
      setUser(user);
    });

    return () => {
      listener();
    };
  }, [auth]);

  useEffect(() => {
    if (user) {
      const { uid, email, displayName } = user;

      analytics.identify(uid, {
        email,
        name: displayName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user && user.email) {
      const { uid, email } = user;

      setLoggingUser({ id: uid, email });
    } else {
      setLoggingUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (user && ldClient) {
      const { uid, email, displayName } = user;

      ldClient.identify({
        kind: 'multi',
        user: {
          key: uid,
          name: displayName || undefined, // LaunchDarkly wants a string or undefined, not a null
          email: email || undefined,
        },
        ...(orgId && {
          organization: {
            key: orgId,
          },
        }),
        ...(pid && {
          project: {
            key: pid,
          },
        }),
      });
    }
  }, [ldClient, user, orgId, pid]);

  const reAuth = () => {
    setIsAuthRecent(false);
    setIsReAuthVisible(true);
  };

  const verifyMFA = (error: FirebaseError) => {
    setIsMFAVisible(true);
    setMFAError(error);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        loading,
        reAuth,
        isAuthRecent,
        verifyMFA,
        isMfaSuccess,
      }}
    >
      {children}

      {user && (
        <SessionIdleDialog
          {...flags[featureFlagIds.sessionIdleTimeouts]}
          logout={inactive => {
            if (inactive) {
              setSnackbar({
                variant: 'info',
                children: 'Your session has expired. Please sign in again.',
                autoHideDuration: 150000,
              });
            }
            router.push(
              getHref(router, navRoutes.logout, {
                [QueryParams.destination]: router.asPath,
              }),
            );
          }}
        />
      )}

      {isCheckingAuth && <LoadingPageProgress />}
      {/* user need to re-auth (non SSO users only) */}
      {isReAuthVisible && (
        <ReAuthDialog
          onSuccess={() => {
            setIsReAuthVisible(false);
            setIsAuthRecent(true);
          }}
          onClose={() => setIsReAuthVisible(false)}
          onLoading={status => setIsCheckingAuth(status)}
        />
      )}
      {/* user need to verify MFA */}
      {isMFAVisible && (
        <MFACodeDialog
          open
          onClose={() => setIsMFAVisible(false)}
          onSuccess={() => {
            setIsMFAVisible(false);
            setIsMfaSuccess(true);
          }}
          raisedError={mfaError}
        />
      )}
    </AuthContext.Provider>
  );
}

export default AuthContext;
